<template>
  <div id="preview">
    <v-card>
      <v-form ref="form1" v-model="valid1" lazy-validation>
        <v-card-title>
          <span class="headline"> {{ pageTitle }} </span>
        </v-card-title>
        <v-card-subtitle class="text-h5">
          <h5>{{ pageDescription }}</h5>
        </v-card-subtitle>
        <v-card-text>
          <small>*indicates required field</small>
          <v-container>
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-btn
                  elevation="30"
                  shaped
                  tile
                  small
                  class="font-size-h6 mr-3 white--text"
                  @click.prevent="refreshPageData"
                  color="#a4c639"
                >
                  Refresh
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <label>*Parent Menu</label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :loading="ParentMenuIdOptionsLoading"
                  :items="ParentMenuIdOptions"
                  :rules="ParentMenuIdRules"
                  v-model="ParentMenuId"
                  required
                  outlined
                  dense
                  clearable
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <label>*Menu Type</label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :loading="MenuTypeIdOptionsLoading"
                  :items="MenuTypeIdOptions"
                  :rules="MenuTypeIdRules"
                  v-model="MenuTypeId"
                  required
                  outlined
                  clearable
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <label>*Module</label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :loading="ModuleIdOptionsLoading"
                  :items="ModuleIdOptions"
                  :rules="ModuleIdRules"
                  v-model="ModuleId"
                  required
                  outlined
                  clearable
                  dense
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <label>*Action Type</label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :loading="ActionTypeIdOptionsLoading"
                  :items="ActionTypeIdOptions"
                  :rules="ActionTypeIdRules"
                  v-model="ActionTypeId"
                  required
                  outlined
                  clearable
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <label>*Menu Name</label>
                <v-text-field
                  v-model="MenuName"
                  :rules="MenuNameRules"
                  hint="Menu Name"
                  required
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <label>*Menu display name</label>
                <v-text-field
                  v-model="DisplayName"
                  :rules="DisplayNameRules"
                  hint="Display Name"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <label>*Menu description</label>
                <v-text-field
                  v-model="Description"
                  :rules="DescriptionRules"
                  hint="Description"
                  required
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <label>*Menu url</label>
                <v-text-field
                  v-model="MenuUrl"
                  :rules="MenuUrlRules"
                  hint="Menu url"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <label>Page Title</label>
                <v-text-field
                  v-model="PageTitle"
                  :rules="PageTitleRules"
                  hint="Page title"
                  required
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <label>Page description</label>
                <v-text-field
                  v-model="PageDescription"
                  :rules="PageDescriptionRules"
                  hint="Page description"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <label>*Order</label>
                <v-text-field
                  v-model="MenuOrder"
                  :rules="MenuOrderRules"
                  hint="Menu Order"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <label>*Icon</label>
                <v-text-field
                  v-model="Icon"
                  :rules="IconRules"
                  hint="Menu icon"
                  required
                  dense
                  outlined
                ></v-text-field>
                <v-icon>{{ Icon }}</v-icon>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <label>*Color</label>
                <v-color-picker v-model="Color"></v-color-picker>
                <!-- <v-text-field
                  v-model="Color"
                  :rules="ColorRules"
                  hint="Menu Color"
                  dense
                  outlined
                ></v-text-field> -->
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn color="blue darken-1" text @click="closePrompt">Close</v-btn>
          <v-btn
            color="blue darken-1"
            text
            :loading="SubmitFlag"
            :disabled="!valid1"
            @click="confirmAlert"
            >Save</v-btn
          > -->
          <v-btn
            @click="closePrompt"
            elevation="30"
            shaped
            tile
            small
            color="#a52a2a"
            class="font-size-h6 px-10 mr-3 white--text"
          >
            Close
          </v-btn>
          <v-btn
            :disabled="!valid1"
            @click.prevent="confirmAlert"
            :loading="SubmitFlag"
            elevation="30"
            shaped
            tile
            small
            color="#1db954"
            class="font-size-h6 px-10 mr-3 white--text"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {},
  props: {
    editRecordPrompt: {
      type: Boolean,
      required: true,
    },
    recordData: {
      required: true,
    },
    pageTitle: {
      type: String,
    },
    pageDescription: {
      type: String,
    },
  },
  data() {
    return {
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      SearchFlag: false,
      SubmitFlag: false,

      rows: [],

      ParentMenuIdRules: [(v) => !!v || "Parent Menu is required"],
      ParentMenuId: "",
      ParentMenuIdOptions: [],
      ParentMenuIdOptionsLoading: false,

      MenuTypeIdRules: [(v) => !!v || "Menu Type is required"],
      MenuTypeId: "",
      MenuTypeIdOptions: [],
      MenuTypeIdOptionsLoading: false,

      ModuleIdRules: [(v) => !!v || "Module is required"],
      ModuleId: "",
      ModuleIdOptions: [],
      ModuleIdOptionsLoading: false,

      ActionTypeIdRules: [(v) => !!v || "Action Type is required"],
      ActionTypeId: "",
      ActionTypeIdOptions: [],
      ActionTypeIdOptionsLoading: false,

      MenuName: "",
      MenuNameRules: [(v) => !!v || "Menu Name is required"],

      DisplayName: "",
      DisplayNameRules: [(v) => !!v || "Display Name is required"],

      Description: "",
      DescriptionRules: [(v) => !!v || "Description is required"],

      PageTitle: "",
      PageTitleRules: [],

      PageDescription: "",
      PageDescriptionRules: [],

      MenuUrl: "#",
      MenuUrlRules: [(v) => !!v || "Menu url is required"],

      Icon: "fas fa-user-shield",
      IconRules: [],

      Color: "#FC3BB5",
      ColorRules: [],

      MenuOrder: 0,
      MenuOrderRules: [],
    };
  },
  computed: {},
  watch: {
    ParentMenuIdOptions: function () {
      console.log("watch ParentMenuIdOptions");
      this.LoadingFlag = false;
      this.ParentMenuIdOptionsLoading = false;
    },
    MenuTypeIdOptions: function () {
      console.log("watch MenuTypeIdOptions");
      this.LoadingFlag = false;
      this.MenuTypeIdOptionsLoading = false;
    },
    ModuleIdOptions: function () {
      console.log("watch ModuleIdOptions");
      this.LoadingFlag = false;
      this.ModuleIdOptionsLoading = false;
    },
    ActionTypeIdOptions: function () {
      console.log("watch ActionTypeIdOptions");
      this.LoadingFlag = false;
      this.ActionTypeIdOptionsLoading = false;
    },
  },
  created() {
    // this.initialize()
  },
  methods: {
    validateForm() {
      console.log("validateForm called");
      this.$refs.form1.validate();
    },
    resetForm() {
      console.log("resetForm called");
      this.$refs.form1.reset();
      //   this.$refs.ProfilePicture.reset();
    },
    resetValidation() {
      console.log("resetValidation called");
      this.$refs.form1.resetValidation();
    },
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideEditRecordPrompt");
    },
    refreshPageData() {
      console.log("refreshPageData called");
      var recordData = this.recordData;
      console.log("recordData=" + JSON.stringify(recordData));
      var MenuId = recordData.MenuId;
      if (MenuId != "") {
        this.getParentMenuIdOptions();
        this.getMenuTypeIdOptions();
        this.getModuleIdOptions();
        this.getActionTypeIdOptions();
        this.ParentMenuId = recordData.ParentMenuId;
        this.MenuTypeId = recordData.MenuTypeId;
        this.ModuleId = recordData.ModuleId;
        this.ActionTypeId = recordData.ModuleActionId;
        // {"MenuId":50,"ModuleId":13,"ModuleName":"Menu","MenuTypeId":4,"MenuTypeName":"Main Menu","ModuleActionId":1,"ModuleActionName":"Parent","MenuName":"Menu Lists - 50","MenuDisplayName":"Menu Lists","ParentMenuId":9,"ParentMenuName":"JCI India Staff main menu","MenuOrder":10,"MenuIcon":"fas fa-user-shield","MenuUrl":"/menu/lists","MenuColor":"#FC3BB5"}
        this.MenuName = recordData.MenuName;
        this.DisplayName = recordData.MenuDisplayName;
        this.Description = recordData.MenuDescription;
        this.PageTitle = recordData.PageTitle;
        this.PageDescription = recordData.PageDescription;
        this.MenuUrl = recordData.MenuUrl;
        this.Icon = recordData.MenuIcon;
        this.Color = recordData.MenuColor;
        this.MenuOrder = recordData.MenuOrder;
      } else {
        this.sweetAlert("error", "Menu details not fount", false);
        this.closePrompt();
      }
    },
    getParentMenuIdOptions() {
      console.log("getParentMenuIdOptions called");
      this.ParentMenuIdOptionsLoading = true;
      var selectbox1_source = "ParentMenuId";
      var selectbox1_destination = "ParentMenuIdOptions";
      var selectbox1_url = "api/menu-details/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getMenuTypeIdOptions() {
      console.log("getMenuTypeIdOptions called");
      this.MenuTypeIdOptionsLoading = true;
      var selectbox1_source = "MenuTypeId";
      var selectbox1_destination = "MenuTypeIdOptions";
      var selectbox1_url = "api/menu-type/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getModuleIdOptions() {
      console.log("getModuleIdOptions called");
      this.ModuleIdOptionsLoading = true;
      var selectbox1_source = "ModuleId";
      var selectbox1_destination = "ModuleIdOptions";
      var selectbox1_url = "api/module/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getActionTypeIdOptions() {
      console.log("getActionTypeIdOptions called");
      this.ActionTypeIdOptionsLoading = true;
      var selectbox1_source = "ActionTypeId";
      var selectbox1_destination = "ActionTypeIdOptions";
      var selectbox1_url = "api/module-actions/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    confirmAlert() {
      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);
      if (validate1) {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Save`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#d33",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.submitForm();
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        this.toast("error", "Kindly fill the required fields", true);
      }
    },
    submitForm() {
      console.log("submitForm is called");

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      // validate1 = false;

      if (validate1) {
        this.SubmitFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/menu-details/update";
        var upload = {
          MenuId: this.recordData.MenuId,
          ParentMenuId: this.ParentMenuId,
          MenuTypeId: this.MenuTypeId,
          ModuleId: this.ModuleId,
          ActionTypeId: this.ActionTypeId,
          MenuName: this.MenuName,
          DisplayName: this.DisplayName,
          Description: this.Description,
          PageTitle: this.PageTitle,
          PageDescription: this.PageDescription,
          MenuUrl: this.MenuUrl,
          Icon: this.Icon,
          Color: this.Color,
          MenuOrder: this.MenuOrder,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SubmitFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.resetForm();
              thisIns.closePrompt();
              thisIns.sweetAlert("success", output, true);
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SubmitFlag = false;
          });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields";
        }
        this.sweetAlert("error", message, false);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
