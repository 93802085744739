<template>
  <div id="lom-list">
    <v-card>
      <v-card-title class="text-h5"> <h2>Menu List</h2> </v-card-title>

      <v-card-subtitle class="text-h5">
        <h4>Search the menu by the filter</h4></v-card-subtitle
      >

      <v-container class="py-0" v-if="tableOptions1.FloatingButtonFlag">
        <v-speed-dial
          v-model="fab"
          :top="top"
          :bottom="bottom"
          :right="right"
          :left="left"
          :direction="direction"
          :open-on-hover="hover"
          :transition="transition"
          :fixed="true"
        >
          <template v-slot:activator>
            <v-btn v-model="fab" color="blue darken-2" dark fab>
              <v-icon v-if="fab"> mdi-close </v-icon>
              <v-icon v-else> mdi-account-circle </v-icon>
            </v-btn>
          </template>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                dark
                small
                color="indigo"
                @click.prevent="addRecord"
                v-if="tableOptions1.CreateButtonFlag"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Create</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                dark
                small
                color="green"
                v-if="tableOptions1.EditButtonFlag"
                @click.prevent="editDataAlert"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                dark
                small
                color="red"
                v-if="tableOptions1.DeleteButtonFlag"
                @click.prevent="deleteDataAlert"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>Delete</span>
          </v-tooltip>
        </v-speed-dial>
      </v-container>

      <v-dialog v-model="addRecordPrompt" persistent max-width="80%">
        <add-new-menu
          pageTitle="Create Menu"
          pageDescription="Add new menu"
          :addRecordPrompt="addRecordPrompt"
          @hideAddRecordPrompt="hideAddRecordPrompt"
          v-if="addRecordPrompt"
        ></add-new-menu>
      </v-dialog>

      <v-dialog
        v-model="editRecordPrompt"
        persistent
        scrollable
        max-width="80%"
      >
        <edit-menu
          pageTitle="Edit Menu"
          pageDescription="Edit menu details"
          :editRecordPrompt="editRecordPrompt"
          :recordData="selectedData"
          @hideEditRecordPrompt="hideEditRecordPrompt"
          v-if="editRecordPrompt"
        ></edit-menu>
      </v-dialog>

      <v-card-text>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>
        <v-container>
          <v-form ref="form2" v-model="valid1" lazy-validation>
            <v-row wrap>
              <v-col cols="12" sm="6" lg="3" md="3">
                <v-select
                  :reduce="(option) => option.value"
                  :items="SearchTypeOptions"
                  :rules="SearchTypeRules"
                  v-model="SearchType"
                  label="Search type"
                  required
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3" v-if="SearchType == 1">
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :items="ModuleCodeOptions"
                  :rules="ModuleCodeRules"
                  :loading="ModuleCodeOptionsLoading"
                  v-model="ModuleCode"
                  label="Module"
                  append-outer-icon="mdi-refresh"
                  @click:append-outer="getModuleCodeOptions"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3" v-if="SearchType == 2">
                <v-text-field
                  v-model="MenuUrl"
                  :rules="MenuUrlRules"
                  label="Menu url"
                  hint="Enter the menu url"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-btn
                  :disabled="!valid1"
                  @click.prevent="searchForm"
                  :loading="SearchFlag"
                  color="#8950FC"
                  elevation="30"
                  shaped
                  tile
                  large
                  class="
                    btn btn-primary
                    font-size-h6
                    px-15
                    py-4
                    my-3
                    mr-3
                    white--text
                  "
                >
                  Search
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-container class="py-0">
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <h3>{{ tableData1.length }} records found.</h3>
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search records here"
                single-line
                hide-details
              ></v-text-field>
              <br />
              <v-data-table
                class="elevation-1"
                loading-text="Loading... Please wait"
                v-model="selected"
                :search="search"
                :loading="SearchFlag"
                :headers="tableColumns1"
                :items="tableData1"
                :items-per-page="tableOptions1.ItemsPerPage"
                :item-key="tableOptions1.ItemKey"
                :single-select="tableOptions1.SingleSelectFlag"
                :show-select="tableOptions1.ShowSelectFlag"
                :footer-props="tableOptions1.FooterProps"
              >
                <template v-slot:item.MenuColor="{ item }">
                  <v-chip dark :color="item.MenuColor">{{
                    item.MenuColor
                  }}</v-chip>
                </template>
                <template v-slot:item.MenuIcon="{ item }">
                  <i
                    class="menu-icon"
                    style="color: #009ada"
                    :class="item.MenuIcon"
                  ></i>
                  <br />
                  {{ item.MenuIcon }}
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="primary"
                        @click="previewData(item, e)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-clipboard-text-search
                      </v-icon>
                    </template>
                    <span> Preview </span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="primary"
                        @click="previewData(item, e)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-file-document-edit
                      </v-icon>
                    </template>
                    <span> Edit </span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
        <br /><br />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import AddNewMenu from "@/view/pages/erp/menu/AddNewMenu.vue";
import EditMenu from "@/view/pages/erp/menu/EditMenu.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";

export default {
  mixins: [common],
  components: {
    AddNewMenu,
    EditMenu,
  },
  data() {
    return {
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      SearchFlag: false,
      SubmitFlag: false,
      rows: [],

      tableColumns1: [],
      tableOptions1: {
        FloatingButtonFlag: true,
        CreateButtonFlag: true,
      },
      tableData1: [],
      tableKey1: "",
      tableItemsPerPage1: 50,
      tableSelectFlag1: false,
      tableSingleSelectFlag1: false,
      tableFooterProps1: {},

      selected: [],
      selectedData: {},
      search: "",
      CurrentYearId: "",

      direction: "top",
      fab: true,
      fling: false,
      hover: true,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: "slide-y-reverse-transition",

      zone: {},
      lom: {},

      SearchType: "",
      SearchTypeRules: [(v) => !!v || "Search type is required"],
      SearchTypeOptions: [
        { value: "", text: "--Select--" },
        { value: 1, text: "By module" },
        { value: 2, text: "By URL" },
      ],

      MenuUrl: "",
      MenuUrlRules: [(v) => !!v || "Menu url is required"],

      JciYearCodeRules: [(v) => !!v || "Year is required"],
      JciYearCode: 11,
      JciYearCodeOptions: [],
      JciYearCodeOptionsLoading: false,

      ModuleCodeRules: [(v) => !!v || "Module is required"],
      ModuleCode: "",
      ModuleCodeOptions: [],
      ModuleCodeOptionsLoading: false,

      addRecordPrompt: false,
      editRecordPrompt: false,
    };
  },
  mounted() {
    /*
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vuetify", route: "alerts" },
      { title: "Form Inputs & Control", route: "autocompletes" },
      { title: "Fileinptus" },
    ]);
    */
  },
  watch: {
    SearchId: function () {
      console.log("watch SearchId");
      this.rows = [];
    },
    ModuleCode: function () {
      console.log("watch ModuleCode");
      // this.$session.set("LomListZoneCode", this.ZoneCode);
    },
    ModuleCodeOptions: function () {
      console.log("watch ModuleCodeOptions");
      // this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
      this.LoadingFlag = false;
      this.ModuleCodeOptionsLoading = false;
    },
  },
  methods: {
    // code 1
    validate() {
      if (this.$refs.form2.validate()) {
        this.snackbar = true;
      }
    },
    hideAddRecordPrompt() {
      console.log("hideAddRecordPrompt called");
      this.addRecordPrompt = false;
      this.searchForm();
    },
    hideEditRecordPrompt() {
      console.log("hideEditRecordPrompt called");
      this.editRecordPrompt = false;
      this.searchForm();
    },
    addRecord() {
      console.log("addRecord called");
      /*
      var url = "/lom/create";
      var params = {};
      this.pageRedirect(params, url);
      */
      this.addRecordPrompt = true;
    },
    editDataAlert() {
      console.log("editDataAlert called");
      var tr = this.selected;
      var n1 = tr.length;
      //   var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        this.selectedData = tr[0];
        this.editRecordPrompt = true;
        // this.confirmAlert();
      } else {
        var message = "Kindly select one record to edit";
        this.sweetAlert("error", message, false);
      }
    },
    resetForm() {
      this.search = "";
      this.rows = [];
      this.selected = [];
      this.$refs.form3.reset();
    },
    resetValidation() {
      this.$refs.form2.resetValidation();
    },
    previewData(tr) {
      console.log("previewData called");
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        var url = "/lom/details";
        var LomId = tr.LomId;
        console.log("LomId=" + LomId);
        var params = {
          id: LomId,
        };
        this.pageRedirect(params, url);
      } else {
        var message = "Kindly select one lom to preview";
        this.toast("error", message);
      }
    },
    refreshPageData() {
      console.log("refreshPageData called");
      // this.JciYearCodeOptionsLoading = true;
      // this.getYearCodeOptions("JciYearCode", "JciYearCodeOptions");
      this.getModuleCodeOptions();
    },
    getModuleCodeOptions() {
      console.log("getModuleCodeOptions called");
      this.LoadingFlag = true;
      this.ModuleCodeOptionsLoading = true;
      var selectbox1_source = "ModuleCode";
      var selectbox1_destination = "ModuleCodeOptions";
      var selectbox1_url = "api/module/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    searchForm() {
      console.log("searchForm is called");

      var validate1 = this.$refs.form2.validate();
      var UserInterface = this.SearchType;
      console.log(
        "validate1=" + validate1 + ", UserInterface=" + UserInterface
      );

      if (validate1) {
        this.SearchFlag = true;
        this.tableData1 = [];
        this.selected = [];
        this.selectedData = {};

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/menu-details/lists";
        var upload = {
          UserInterface: UserInterface,
          ModuleCode: this.ModuleCode,
          MenuUrl: this.MenuUrl,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.SearchFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            thisIns.tableOptions1 = records.TableOptions;
            thisIns.tableColumns1 = records.TableHeader;

            if (flag == 1) {
              thisIns.tableData1 = records.TableData;
              thisIns.toast("success", output);
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SearchFlag = false;
          });
      } else {
        var message = "Kindly fill the required fields";
        this.toast("error", message);
      }
    },
  },
  mounted() {
    // this.searchForm();
  },
  beforeMount() {
    var CurrentYearId = this.$session.get("CurrentYearId");
    CurrentYearId = 1;
    CurrentYearId = CurrentYearId == (null || undefined) ? 0 : CurrentYearId;
    this.CurrentYearId = CurrentYearId;
    var ZoneCode = this.$session.get("LomListZoneCode");
    ZoneCode = ZoneCode == (null || undefined) ? "" : ZoneCode;
    this.ZoneCode = ZoneCode;
    this.refreshPageData();
  },
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */

#lom-list {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
}
</style>